<template>
  <section id="dashboard-ecommerce"><br>
	<b-modal
		id="affsuppplan"
		ref="my-modal"
		title="Validation"
		button-size="sm"
		centered
		no-fade
		ok-only
		ok-title="Fermer"
    >
      <div class="d-block text-center" />
		<b-button
			variant="outline-primary"
			@click="delplan()"
		>
		Oui supprimer le plan
		</b-button>
    </b-modal>
    <b-row >
    	<b-col 
			cols="7"
		>
    	<home-medal :resultSoc='ResultSoc' /><br>	
		  <b-card>
			<vue-good-table
				:columns="columns"
				:rows="rows"
				:pagination-options="{
					enabled: true,
					mode: 'records',
					perPage: 15,
					position: 'top',
					perPageDropdown: [5, 10, 15,  20],
					dropdownAllowAll: false,
					jumpFirstOrLast : true,
					firstLabel : 'Première Page',
					lastLabel : 'Dernière Page',
					nextLabel: 'Suivant',
					prevLabel: 'Précédent',
					rowsPerPageLabel: 'ligne par page',
					ofLabel: 'de',
					pageLabel: 'page', // for 'pages' mode
					allLabel: 'Tout',
					infoFn: (params) => `my own page ${params.firstRecordOnPage}`, 
					}"
				:search-options="{
					enabled: true,
					placeholder: 'Recherche',
					}"
				@on-row-click="onRowClick"
			>
			  <template
				slot="table-row"
				slot-scope="props"
			  >
				<span
				  v-if="props.column.field === 'C_libelle'"
				  class="text-nowrap"
				>
				  <b-avatar
					:src="props.row.C_logop"
					class="mx-1"
				  />
				  <span>{{ props.row.C_libelle }}</span>
				</span>
				<span v-else>
				  {{ props.formattedRow[props.column.field] }}
				</span>
			  </template>
			</vue-good-table>
		</b-card>
      </b-col>
      <b-col>
		  <b-card
			no-body
			v-if="CUI !== ''"
			class="card-developer-meetup"
		  >
			<div class="bg-light-primary rounded-top text-center">
			  <b-img
				:src="selctrow.C_logo"
				fluid
			  />
			</div>
			<b-card-body>
			<!-- metting header -->
			<div class="meetup-header d-flex align-items-center">
			<div class="meetup-day">
			  <h6 class="mb-0">
				BX
			  </h6>
			  <h3 class="mb-0">
				2022
			  </h3>
			</div>
			<div class="my-auto">
			  <b-card-title class="mb-25">
				{{selctrow.C_libelle}}
			  </b-card-title>
			  <b-card-text class="mb-0">
				{{selctrow.C_adresse}}
			  </b-card-text>
			  <b-card-text class="mb-0">
				{{CUI}}
			  </b-card-text>
			</div>
			</div>
			<!--/ metting header -->

			<!-- media -->
			<b-media
			v-for="media in mediaData"
			:key="media.avatar"
			no-body
			>
			<b-media-aside class="mr-1">
			  <b-avatar
				rounded
				variant="light-primary"
				size="34"
			  >
				<feather-icon
				  :icon="media.avatar"
				  size="18"
				/>
			  </b-avatar>
			</b-media-aside>
			<b-media-body>
			  <h6 class="mb-0">
				{{ media.title }}
			  </h6>
			  <small>{{ media.subtitle }}</small>
			</b-media-body>
			</b-media>

			<!-- avatar group -->
			<b-avatar-group
			class="mt-2 pt-50"
			size="33"
			>
			<b-avatar
			  v-for="avatar in avatars"
			  :key="avatar.avatar"
			  v-b-tooltip.hover.bottom="avatar.fullName"
			  :src="avatar.avatar"
			  class="pull-up"
			/>
			</b-avatar-group>
			<hr>
			<h5 class="align-self-center cursor-pointer ml-1 mb-0">Informations générale</h5>
			<div class="demo-inline-spacing">
				<b-form-checkbox
				v-model="bnationalbox"
				value="1"
				plain
			  >
				Centre National Box
			  </b-form-checkbox>
			</div>
			<br>
			<b-button
				v-ripple.400="'rgba(255, 255, 255, 0.15)'"
				variant="primary"
				@click="majsparamsoc()"
			>
			  Valider
			</b-button>
			<hr>
			<h5 class="align-self-center cursor-pointer ml-1 mb-0">Fonction signatures électroniques</h5>
			<div class="demo-inline-spacing">
				<b-form-radio
				v-model="bsignatureactive"
				plain
				name="rbsignatureactive"
				value="1"
				>
				Active
				</b-form-radio>
				<b-form-radio
				v-model="bsignatureactive"
				plain
				name="rbsignatureactive"
				value="0"
				>
				Inactive
				</b-form-radio>
			</div>
			<div class="demo-inline-spacing"  v-if="bsignatureactive">
				<b-form-radio
				v-model="bsignaturmarquage"
				plain
				name="rbsignaturmarquage"
				value="1"
				>
				Marquage activé (Bande sur la gauche)
				</b-form-radio>
				<b-form-radio
				v-model="bsignaturmarquage"
				plain
				name="rbsignaturmarquage"
				value="0"
				>
				Marquage déactivé
				</b-form-radio>
			</div>
			<div class="demo-inline-spacing" v-if="bsignatureactive">
				<b-form-radio
				v-model="bsignatureprod"
				plain
				name="rbsignatureprod"
				value="1"
				>
				En production
				</b-form-radio>
				<b-form-radio
				v-model="bsignatureprod"
				plain
				name="rbsignatureprod"
				value="0"
				>
				En test
				</b-form-radio>
			</div>
			<b-form-group
				label="Nb signatures par mois"
				label-for="nbsign"
				v-if="bsignatureactive"
			>
			  <b-form-input
				v-model="nbsign"
				id="nbsign"
			  />
			</b-form-group>
			<b-button
				v-ripple.400="'rgba(255, 255, 255, 0.15)'"
				variant="primary"
				@click="majsign()"
			>
			  Valider
			</b-button>
			<hr>
			<h5 class="align-self-center cursor-pointer ml-1 mb-0">Plans</h5><br>
			<b-row>
				<b-col>
					<b-form-group
						label="Libelle du plan"
						label-for="libelleplan"
					>
					  <b-form-input
						v-model="libplan"
						id="libelleplan"
					  />
					</b-form-group>
				</b-col>
				<b-col>
					<b-form-group
						label="Liste des sites"
						label-for="soc"		
					 >
						<b-form-select
						id="soc"
						v-model = "idsite"
						:options="resultSite"
						/>
					</b-form-group>
				</b-col>
			</b-row>	
			<br>
			<b-button
				v-ripple.400="'rgba(255, 255, 255, 0.15)'"
				variant="primary"
				@click="addplan()"
			>
			  Ajouter un plan
			</b-button>
			<br><br>
			<span class="text-caption">Les plans affichés dans le navigateur sont decompressés : il faut donc bien les enregistrer de nouveau au format svgz</span><br><br>
			<vue-good-table
				:columns="columnsplan"
				:rows="rowsplan"
				:select-options="{ 
				enabled: true,
				selectOnCheckboxOnly: true, 
				selectionText: 'plan(s) sélectionné(s)',
				clearSelectionText: 'annuler'
				}"
				:sort-options="{
				enabled: true,
				}"
				@on-selected-rows-change="selectionPlanChanged"
			>
				<div slot="selected-row-actions"
				>
					<div  v-show="rowPlanSelection.length === 1"
					>
						<br>
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							variant="primary"
							@click="dialdelplan(rowPlanSelection[0].C_UI)"
						>
						  Supprimer le plan
						</b-button>
						<br>
						<hr>
						<b-form-file
							v-model="file1"
							accept=".svgz"
							:state="Boolean(file1)"
							placeholder="Choisissez un fichier ou déposez-le ici.."
							drop-placeholder="Déposez le fichier ici..."
						></b-form-file>
						<div class="mt-3" v-if="file1">Plan : {{rowPlanSelection[0].C_UI}}</div>
						<button class="btn btn-success" v-if="file1" @click="upload(rowPlanSelection[0].C_UI)">
						  Upload
						</button>
						<div v-if="file1" class="progress">
							<div
							class="progress-bar progress-bar-info progress-bar-striped"
							role="progressbar"
							:aria-valuenow="progress"
							aria-valuemin="0"
							aria-valuemax="100"
							:style="{ width: progress + '%' }"
							>
							{{ progress }}%
							</div>
						</div>
					</div>
				</div>
				<template
				slot="table-row"
				slot-scope="props"
				>
				<span
				  v-if="props.column.field === 'C_libelle'"
				  class="text-nowrap"
				>
					<b-button
						v-ripple.400="'rgba(113, 102, 240, 0.15)'"
						variant="outline-primary"
						size="sm"
						@click="voirplan(props.row.C_UI)"
					>
					Voir
					</b-button>
					<span> {{ props.row.C_libelle }}</span>
				</span>
				<span v-else>
				  {{ props.formattedRow[props.column.field] }}
				</span>
				</template>
			</vue-good-table>
			<hr>
			<h5 class="align-self-center cursor-pointer ml-1 mb-0">Sites</h5><br>
			<b-row>
				<b-col>
					<b-form-group
						label="Libelle du site"
						label-for="libellesite"
					>
					  <b-form-input
						v-model="libsite"
						id="libellesite"
					  />
					</b-form-group>
				</b-col>
			</b-row>	
			<b-button
				v-ripple.400="'rgba(255, 255, 255, 0.15)'"
				variant="primary"
				@click="addsite()"
			>
			  Ajouter un site
			</b-button>
			<br><br>
			<vue-good-table
				:columns="columnsite"
				:rows="rowsite"
			>
				<div slot="selected-row-actions"
				>
					<div  v-show="rowPlanSelection.length === 1"
					>
						<br>
						<b-button
							v-ripple.400="'rgba(255, 255, 255, 0.15)'"
							variant="primary"
							@click="dialdelplan(rowPlanSelection[0].C_UI)"
						>
						  Supprimer le plan
						</b-button>
						<br>
						<hr>
						<b-form-file
							v-model="file1"
							accept=".svgz"
							:state="Boolean(file1)"
							placeholder="Choisissez un fichier ou déposez-le ici.."
							drop-placeholder="Déposez le fichier ici..."
						></b-form-file>
						<div class="mt-3" v-if="file1">Plan : {{rowPlanSelection[0].C_UI}}</div>
						<button class="btn btn-success" v-if="file1" @click="upload(rowPlanSelection[0].C_UI)">
						  Upload
						</button>
						<div v-if="file1" class="progress">
							<div
							class="progress-bar progress-bar-info progress-bar-striped"
							role="progressbar"
							:aria-valuenow="progress"
							aria-valuemin="0"
							aria-valuemax="100"
							:style="{ width: progress + '%' }"
							>
							{{ progress }}%
							</div>
						</div>
					</div>
				</div>
				<template
				slot="table-row"
				slot-scope="props"
				>
				<span
				  v-if="props.column.field === 'C_libelle'"
				  class="text-nowrap"
				>
					<span v-if="props.row.B_plan === 0"> 
						{{ props.row.C_libelle }} (Fichier inexistant)
					</span>
					<span v-else>
					  {{ props.row.C_libelle }} 
					</span>
				</span>
				<span v-else>
				  {{ props.formattedRow[props.column.field] }}
				</span>
				</template>
			</vue-good-table>
			</b-card-body>
		  </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import { BRow, BCol, BAvatar, BCard, BImg, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BAvatarGroup, VBTooltip, BMediaBody, BFormRadio, BFormInput, BFormGroup, BButton, BFormCheckbox, BFormFile, BModal, BFormSelect } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import HomeMedal from './HomeMedal.vue'
	
export default {
	components: {
		BRow,
		BCol,
		BAvatar,
		VueGoodTable,
		BCard, 
		BImg, 
		BCardBody, 
		BCardText, 
		BCardTitle, 
		BMedia, 
		BMediaAside, 
		BAvatarGroup, 
		BMediaBody,
		BFormRadio,
		BFormInput,
		BFormGroup,
		BButton,
		BFormCheckbox,
		BFormFile,
		BFormSelect,
		BModal,
		HomeMedal,
	},
	directives: {
		Ripple,
		'b-tooltip': VBTooltip,
	},
	data() {
		return {
			resultSoc: [],
			resultSite: [],
			idsite: '',
			columns: [
				{
					label: 'Libelle',
					field: 'C_libelle',
				},
				{
				  label: 'Société',
				  field: 'C_rs',
				},
				{
				  label: 'National Box',
				  field: 'C_nationalbox',
				},
				{
				  label: 'Exp. compta',
				  field: 'B_compta',
				},
				{
				  label: 'Signature',
				  field: 'B_signature',
				},
				{
				  label: 'Nb sign.',
				  field: 'I_nbmois',
				},
			],
			rows: [],
			selctrow: [],
			columnsplan: [
				{
					label: 'Libelle',
					field: 'C_libelle',
				},
				{
					label: 'Site',
					field: 'C_site',
				},
				{
				  label: 'Ordre',
				  field: 'I_ordre',
				},
			],
			rowsplan: [],
			rowPlanSelection: [],
			columnslog: [
				{
					label: 'Date',
					field: 'C_date',
				},
				{
					label: 'IP',
					field: 'C_ip',
				},
				{
				  label: 'Pays',
				  field: 'C_country',
				},
				{
				  label: 'Etat',
				  field: 'C_type',
				},
				{
				  label: 'Email',
				  field: 'C_email',
				},
				{
				  label: 'Société',
				  field: 'C_rs',
				},
				{
				  label: 'Utilisateur',
				  field: 'C_utilisateur',
				},
			],
			rowsite: [],
			columnsite: [
				{
					label: 'Libelle',
					field: 'C_libelle',
				},
			],
			rowslog: [],
			mediaData: [],
			avatars: [],
			bsignatureactive: 0,
			bsignatureprod: 0,
			bsignaturmarquage: 0,
			nbsign: 0,
			bnationalbox: 0,
			CUI: '',
			libplan: '',
			libsite: '',
			file1: null,
			progress: 0,
			CUIPLAN: '',
		}
	},
	created() {
		this.$http.get('/admin/lstcompte')
		.then(response => {
			if (response.status === 200) {
				this.ResultSoc = response.data.ResultSoc
			} else {
				this.$router.push('/login')	
			}
		})
		this.$http.get('/admin/lstsoc')
		.then(response => { this.rows = response.data.ResultSoc })
	},	  
	methods: {
		onRowClick(params) {
			this.CUI = params.row.C_UI
			this.selctrow = { C_libelle: params.row.C_libelle, C_adresse: `${params.row.C_adresse} ${params.row.C_cp} ${params.row.C_ville}`, C_tel: params.row.C_tel, C_logo: params.row.C_logo }
			this.mediaData = [
				{ avatar: 'UserIcon', title: 'Contact', subtitle: `${params.row.C_prenom}  ${params.row.C_nom}` },
				{ avatar: 'PhoneCallIcon', title: 'Téléphone', subtitle: params.row.C_tel },
				{ avatar: 'MailIcon', title: 'E-mail', subtitle: params.row.C_email },
			]
			this.nbsign = 0
			this.bsignatureactive = 0
			this.bsignatureprod = 0
			this.bsignaturmarquage = 0
			
			let LBbiometrichash = ''
			if (params.row.B_nationalbox === 1) {
				this.bnationalbox = 1
			} else {
				this.bnationalbox = 0
			}
			if (params.row.B_signatureactive === 1) {
				this.bsignatureactive = 1
				this.nbsign = params.row.I_nbmois
				if (params.row.B_biometric_hash === 1) {
					this.bsignaturmarquage = 1
					LBbiometrichash = 'Bande de signature activée'	
				} else {
					this.bsignaturmarquage = 0
					LBbiometrichash = 'Bande de signature désactivée'	
				}
				if (params.row.B_prod === 1) {
					this.bsignatureprod = 1
					this.mediaData.push({
						avatar: 'CheckCircleIcon',
						title: 'Signature',
						subtitle: `En production : ${params.row.I_nbmois} signatures par mois ${LBbiometrichash}`,
					})	
				} else {
					this.bsignatureprod = 0
					this.mediaData.push({
						avatar: 'CheckCircleIcon',
						title: 'Signature',
						subtitle: `En test : ${params.row.I_nbmois} signatures par mois ${LBbiometrichash}`,
					})	
				}
			}
			this.$http.get(`/admin/lstplan/${this.CUI}`)
			.then(response => { this.rowsplan = response.data.Rplan })
			this.$http.get(`/admin/lstsite/${this.CUI}`)
			.then(response => { this.rowsite = response.data.Rsite })
			this.$http.get(`/admin/lstsite/${this.CUI}`)
			.then(response => { this.resultSite = response.data.Rsite })
		},
		majsign() {
			this.$http.post('/admin/majsign', { CUIS: this.CUI, bsign: this.bsignatureactive, bprod: this.bsignatureprod, nbsign: this.nbsign, bmarquage: this.bsignaturmarquage })
			.then(response => {
				if (response.data.res === 1) {
					this.rows = []
					this.mediaData = []
					this.selctrow = []
					this.CUI = ''
					this.nbsign = 0
					this.bsignatureactive = 0
					this.bsignaturmarquage = 0
					this.bsignatureprod = 0
					this.bnationalbox = 0
					this.LBbiometrichash = ''
					this.$http.get('/admin/lstsoc')
					.then(responselstsoc => { this.rows = responselstsoc.data.ResultSoc })
					this.$toast({
					component: ToastificationContent,
					position: 'top-right',
						props: {
						  title: 'Ok c\'est fait ! 🎉',
						  icon: 'CheckIcon',
						  variant: 'success',
						  text: 'Modifications enregistrées',
						},
					},
					{
					timeout: 8000,
					position: 'top-center',
					})
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'danger',
							text: 'Erreur : action impossible',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})
				}
			})	
		},
		majsparamsoc() {
			this.$http.post('/admin/majsparamsoc', { CUIS: this.CUI, bnationalbox: this.bnationalbox })
			.then(response => {
				if (response.data.res === 1) {
					this.rows = []
					this.mediaData = []
					this.selctrow = []
					this.CUI = ''
					this.nbsign = 0
					this.bsignatureactive = 0
					this.bsignaturmarquage = 0
					this.bsignatureprod = 0
					this.bnationalbox = 0
					this.LBbiometrichash = ''
					this.$http.get('/admin/lstsoc')
					.then(responselstsoc => { this.rows = responselstsoc.data.ResultSoc })
					this.$toast({
					component: ToastificationContent,
					position: 'top-right',
						props: {
						  title: 'Ok c\'est fait ! 🎉',
						  icon: 'CheckIcon',
						  variant: 'success',
						  text: 'Modifications enregistrées',
						},
					},
					{
					timeout: 8000,
					position: 'top-center',
					})
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'danger',
							text: 'Erreur : action impossible',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})
				}
			})	
		},
		addplan() {
			if ((this.libplan !== '') && (this.idsite !== '')) {
				this.$http.post('/admin/addplan', { CUIS: this.CUI, libplan: this.libplan, cuisite: this.idsite })
				.then(response => {
					if (response.data.res === 1) {
						this.libplan = ''
						this.idsite = ''
						this.$http.get(`/admin/lstplan/${this.CUI}`)
						.then(responselstplan => { this.rowsplan = responselstplan.data.Rplan })
						this.$toast({
						component: ToastificationContent,
						position: 'top-right',
							props: {
							  title: 'Ok c\'est fait ! 🎉',
							  icon: 'CheckIcon',
							  variant: 'success',
							  text: 'Modifications enregistrées',
							},
						},
						{
						timeout: 8000,
						position: 'top-center',
						})
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Oups',
								icon: 'SmileIcon',
								variant: 'danger',
								text: 'Erreur : action impossible',
							},
						},
						{
							timeout: 4000,
							position: 'top-center',
						})
					}
				})	
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Oups',
						icon: 'SmileIcon',
						variant: 'danger',
						text: 'Erreur : Merci de choisir un site et saisir un libellé',
					},
				},
				{
					timeout: 4000,
					position: 'top-center',
				})
			}
		},
		addsite() {
			if (this.libsite !== '') {
				this.$http.post('/admin/addsite', { CUIS: this.CUI, libsite: this.libsite })
				.then(response => {
					if (response.data.res === 1) {
						this.libsite = ''
						this.$http.get(`/admin/lstsite/${this.CUI}`)
						.then(responseaddsite => { this.rowsite = responseaddsite.data.Rsite })
						this.$toast({
						component: ToastificationContent,
						position: 'top-right',
							props: {
							  title: 'Ok c\'est fait ! 🎉',
							  icon: 'CheckIcon',
							  variant: 'success',
							  text: 'Modifications enregistrées',
							},
						},
						{
						timeout: 8000,
						position: 'top-center',
						})
					} else {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Oups',
								icon: 'SmileIcon',
								variant: 'danger',
								text: 'Erreur : action impossible',
							},
						},
						{
							timeout: 4000,
							position: 'top-center',
						})
					}
				})	
			} else {
				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Oups',
						icon: 'SmileIcon',
						variant: 'danger',
						text: 'Erreur : Merci de saisir un libellé',
					},
				},
				{
					timeout: 4000,
					position: 'top-center',
				})
			}
		},
		selectionPlanChanged(params) {
			this.rowPlanSelection = params.selectedRows
		},
		voirplan: function (ui) {   
		  window.open(`https://ubicx.blob.core.windows.net/bbc/plans/${ui}.svgz`, '_blank')
		},
		upload(CUIP) {
			const formData = new FormData()
			formData.append('CUIP', CUIP)
			formData.append('file', this.file1)
			const headers = { 'Content-Type': 'multipart/form-data' }
			this.$http.post('/admin/uploadplan', formData, { headers })
			.then(response => {
				if (response.data.res === 1) {
					this.libplan = ''
					this.file1 = null
					this.$http.get(`/admin/lstplan/${this.CUI}`)
					.then(responselstplan => { this.rowsplan = responselstplan.data.Rplan })
					this.$toast({
					component: ToastificationContent,
					position: 'top-right',
						props: {
						  title: 'Ok c\'est fait ! 🎉',
						  icon: 'CheckIcon',
						  variant: 'success',
						  text: 'Modifications enregistrées',
						},
					},
					{
					timeout: 8000,
					position: 'top-center',
					})
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'danger',
							text: 'Erreur : action impossible',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})
				}
			})	
		},
		dialdelplan(CUIP) {
			this.CUIPLAN = CUIP
			this.$bvModal.show('affsuppplan')
		},
		delplan() {
			this.$http.post('/admin/delplan', { CUIP: this.CUIPLAN })
			.then(response => {
				if (response.data.res === 1) {
					this.$bvModal.hide('affsuppplan')
					this.libplan = ''
					this.CUIPLAN = ''
					this.$http.get(`/admin/lstplan/${this.CUI}`)
					.then(responselstplan => { this.rowsplan = responselstplan.data.Rplan })
					this.$toast({
					component: ToastificationContent,
					position: 'top-right',
						props: {
						  title: 'Ok c\'est fait ! 🎉',
						  icon: 'CheckIcon',
						  variant: 'success',
						  text: 'Modifications enregistrées',
						},
					},
					{
					timeout: 8000,
					position: 'top-center',
					})
				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Oups',
							icon: 'SmileIcon',
							variant: 'danger',
							text: 'Erreur : action impossible',
						},
					},
					{
						timeout: 4000,
						position: 'top-center',
					})
				}
			})	
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
